.navbar-brand:hover {
  color: white;
}

.nav-link:hover {
  background-color: white;
  color: black;
  padding: 0.5rem;
}

.search-container {
  position: relative;
}

.background-image {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
}

.search-form {
  position: relative;
  z-index: 1;
}

.card {
  width: 100%;
}

.card-addCar {
  max-width: 580px;
  padding: 30px;
  margin: auto;
  border-radius: 5%;
}

.card-account {
  width: 700px;
  margin: auto
}

.offset-custom {
  margin-left: -320px;
}

.user-info {
  margin-left: 22%;
}

.titre-formulaire {
  text-align: center;
  color: #00008B; 
}

.car-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.card-body {
  padding: 16px;
}

.car-card {
  flex: 1 1 calc(33.333% - 16px);
  box-sizing: border-box;
  margin-bottom: 16px;
}

.car-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.custom-password {
  color: red;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.calendar-custom {
  margin-top: 10px;
}

.react-calendar {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  border-radius: 5px;
}

.reservation-details {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.reserved-tile {
  background-color: lightgreen;
  height: 100%;
  width: 100%;
}